

.carousel-control-next-icon {
  position: absolute;
  right: 0px;
  background: #FFFFFF;
  height: 70px;
  width: 35px;
}

.carousel-control-prev-icon {
  position: absolute;
  left: 0px;
  background: #FFFFFF;
  height: 70px;
  width: 35px;

}

.arrow {
  margin-top: 24px;
}

.circle {
  width: 16px !important;
  height: 16px !important;
  border-radius: 36px;
  border: rgba(255, 255, 255, .5) solid 1px !important;
  background: transparent !important;
}

.active {
  background: rgba(255, 255, 255, .5) !important;

}



.carousel-holder {
  /* border: black solid 1px; */
 display: block;
 /* justify-content: right; */
 width: 90%;
 margin: auto;
}


.galler-wrapper {
  /* border: black solid 1px; */
  position: relative;   
  display: flow-root;
}

.img-caro {
  /* visibility: hidden; */
  /* border-radius: 1em; */
  /* display: inline; */
  margin: 0em;

}
.alice-carousel__stage-item {
  /* width: 700px !important; */
}
@media screen and (max-width: 1023px) {
.carousel-holder {
  width: 100%; 
  /* changed from 90% */
}

}


@media screen and (max-width: 800px) {
.carousel-control-next-icon,
.carousel-control-prev-icon {
  visibility: hidden;
}

.circle {
  width: 8px !important;
  height: 8px !important;
}

}