.nav-button-bar {
  /* border: 1px solid black; */
  /* align-items: center; */
  align-content: center;
  display: flex;
  justify-content: center;
background-color: #234041;
  height: 3.4em;
  margin-top: -.3em;


}

div.nav-container {
  font-family: Raleway;
  margin: 0 auto;
  padding: 10em 3em;
  text-align: center;
}

div.nav-container a {
  color: #FFF;
  text-decoration: none;
  font: 20px Raleway;
  margin: 0px 10px;
  padding: 10px 10px;
  position: relative;
  z-index: 0;
  cursor: pointer;
}


div.pullRightLeft a:before,
div.pullRightLeft a:after {
  position: absolute;
  width: 2px;
  height: 100%;
  top: 0px;
  content: '';
  background: #FFF;
  opacity: 0.3;
  transition: all 0.3s;
}

div.pullRightLeft a:before {
  left: 0px;
}

div.pullRightLeft a:after {
  right: 0px;
}

div.pullRightLeft a:hover:before,
div.pullRightLeft a:hover:after {
  width: 100%;
}



.nav-button {
  color: #FFF;
  text-decoration: none;
  font: 20px Raleway;
  border: none;
  background-color: transparent;
  position: relative;
  z-index: 0;
  cursor: pointer;
}


/* .nav-button:focus {
  background-color: #606c38;
  transition: 20ms;

}

.nav-button:hover {
  background-color: #606c38;
  transition: 20ms;
} */

.nav-button-bar-reg {
  visibility: visible;
}

.nav-button-bar-mobile {
  visibility: hidden;
  /* position: absolute; */
}


.nav-button-bar {
  position: relative;
  align-items: center;
  width: 100%;

}



@media screen and (max-width: 1020px) {
  .nav-container {
    visibility: hidden;
    width: 0%;

  }

  .nav-button-bar-mobile {
    visibility: visible;
    position: absolute;
     left: 1em;
     top: 1em;
     height: 1.4em;
     width: 14.5%;
  }
}