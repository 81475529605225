@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/montserrat-v15-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/montserrat-v15-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./fonts/montserrat-v15-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./fonts/montserrat-v15-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('./fonts/montserrat-v15-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./fonts/montserrat-v15-latin-regular.svg#Montserrat') format('svg');
  /* Legacy iOS */
}

/* raleway-regular - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/raleway-v22-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/raleway-v22-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./fonts/raleway-v22-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./fonts/raleway-v22-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('./fonts/raleway-v22-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./fonts/raleway-v22-latin-regular.svg#Raleway') format('svg');
  /* Legacy iOS */
}
@font-face {
  font-family: 'Birthstone';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/birthstone-v2-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/birthstone-v2-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./fonts/birthstone-v2-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./fonts/birthstone-v2-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('./fonts/birthstone-v2-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./fonts/birthstone-v2-latin-regular.svg#Birthstone') format('svg');
  /* Legacy iOS */
}

h1,h2,h3,h4,h5,h6 {
  margin-top: 1em;
}
.App {
  /* text-align: center; */
 min-height: 100%;
 min-width: 100%;
 /* display: flex; */
 /* flex-direction: column; */
   font-family: 'Montserrat';
   font-style: normal;
   font-weight: 400;
  background-color: #f5f5f5f5;
  overflow: hidden;
  
}
.banner {
  width: 100%;
  opacity: .5;
  margin-top: -5em;
}
.wrapper {
  min-height: 100%;

  /* Equal to height of footer */
  /* But also accounting for potential margin-bottom of last child */
  margin-bottom: -50px;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

button{
  transition: background 250ms
  ease-in-out,
  transform 150ms ease;
   outline: none;
    box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
   outline: none;
   box-shadow: none;
   -webkit-appearance: none;
   -moz-appearance: none;
}

button:active {
  transform: scale(0.99);
    outline: none;
    box-shadow: none;
     -webkit-appearance: none;
     -moz-appearance: none;
}