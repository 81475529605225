
.left {
  background: #ccc;
  /* display: flex; */
  border-top: black 1px solid;
  width:  100%;
  min-width: 25em;
  
  /* margin: 1em */
}
.order-head h5{
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1em;

}

.order-head h2 {
     margin-bottom: 1.5em;
  font-weight: 600;
   
    }

    
    
    .left ul {
      list-style: none;
      
    }
    
    
    .remove-button {
      background: none;
      border: none;
      /* position: relative; */
      /* bottom: 1em; */
      text-align: center;
      height: 1.5em;
    }
    
    .remove-button:hover{
      background-color: salmon;
      color: #fefefe;
      border-radius: 1em;
    }

.order-head {
  display: flex;
  width: 100%;
  padding: .5em;
  position: relative;
}

/* .order-head h5 {
  position: absolute;
  
  left: 25%;
} */


.product {
 position: absolute;
 bottom: -.5em;
 left: 4%;
}
.quant{
  position: absolute;
  bottom: -.5em;
  left: 32%;
}

.ppu {
  position: absolute;
  bottom: -.5em;
  left: 55%;
  
}

.ttl {
  position: absolute;
  bottom: -.5em;
  left: 80%;
}

.os-total-holder {
  position: relative;
  width: 100%;
  /* border: solid black 1px; */
  height: 15em;
}
.os-total-holder h3{
  font-size: 1.2rem;
}
/* .quant */
.subtotal-os {
   position: absolute;
   /* bottom: -.5em; */
   left: 2%;
}
.hst-os {
  position: absolute;
  bottom: 25%;
  left: 2%;
}
.total-os {
  position: absolute;
  bottom: 15%;
  left: 2%;
  font-weight: 600;
}


.item-checkout {
  width: 100%;
  /* border:2px solid black; */
  display: flex;
  position: relative;
}


.item-checkout h4 {
font-size: 1rem;
}

.item-checkout h5 {
font-size: 1rem;

}
.name-and-button {
  display: flex;
  /* position: absolute; */
  bottom: -.5em;
  left: 15%;
}

.co-value {
  position: absolute;
  bottom: -.1em;
  left: 29%;
  display: flex;
}
.co-value h4 {
  padding-left: .5em;
  padding-right: .5em;
}

.co-value-btn {
  height: 2em;
  width: 2em;
 font-family: 'Montserrat';
 font-style: normal;
 font-weight: 400;
 border-radius: .2em;
 border: none;
 background-color: #49974E;
 color: #f3f3f3;
 transition: 2ms;
 }

 #minus {
   background: #EB5252;
   opacity: .6;
   margin-left: .1em; 
 }

 .co-value-btn:hover {
   transition: 2ms;
   background-color: #356E39;


 }

 #minus:hover {
  opacity: 1;
 }




.co-price {
  position: absolute;
  bottom: -.5em;
  left: 55%;

}

.co-total {
  position: absolute;
  bottom: -.5em;
  left: 80%;
}

.checkbox-holder {
border-top: 1px solid black;
border-bottom: 1px solid black;

position: absolute;
top: 3em;
width: 99%;
height: 5em;
padding: .2em;
}

.checkbox-holder input,
.checkbox-holder label {
  float: right;
  margin-right: 1em;
  /* margin-top: 1em; */

}
.deliver-holder {
  margin: 1em;
}
.pick-up-holder {
  margin: 1em;
}


@media screen and (max-width: 1020px) {
  .ttl, .co-total {
    left: 89%;
  }

  .ppu, .co-price {
    left: 66%;
  }

  .co-value, .quant {
    left: 48%;
  }

  .co-button-wrap {
    display: inline-grid;
  }
  #minus {
    margin-left: 0em;
  }
}

@media screen and (max-width: 540px) {
  .name-and-button{
        width: 25%;
        margin-left: -1.5em;
  }
 .co-value, .quant  {
  left: 30%;
 } 

 .ttl,
 .co-total {
   left: 80%;
 }

 .ppu,
 .co-price {
   left: 57%;
 }
.ppu {
  width: 50%;
}
}


@media screen and (max-width: 380px) { 
 .co-value,
 .quant {
   left: 20%;
 }
.ppu {
  left: 38%;
}
 .co-price {
  left: 40%;
}

 .ttl,
 .co-total {
   left: 68%;
 }


}


