.home-container {
  text-align: none;
}

.main-holder,
.main-holder-two {
  /* border: #606c38 solid 1px; */
  display: flex;
  margin-top: 2em;
  margin-bottom: 2em;

}

.home-title {
  color: #606c38;
  margin-left: 5%;
  margin-top: 2em;
  margin-bottom: 2em;
}

.home-para {
  width: 66%;
line-height: 1.5em; 
  margin: auto;
}

.specialize {
  margin-left: 7%;
  color: #679436;

}

.card p {
  line-height: 1.5em;
}
.quest {
  /* border: .5px solid black; */
  color: #ffffff;
  background: #234041;
  /* background-image: url('https://lh3.googleusercontent.com/pw/AM-JKLV1i__JCfer1OzUUslzQoPFHaMY47eoBX3Zq6f5GLJ6ibibgOTNMdzAs7Jwob65lG-gYUXz2cXPEMXnYqmqfjkljNB9gFZ1vjdWPLLWHuK2L-MGVlzyKpjUftyDHVFCdQCoV8B7wB6xP8sIvgnx14a8=w623-h416-no?authuser=0'); */

  width: 40%;
  height: 10em;
  text-align: center;
  margin-right: 5%;
  border-radius: 1em;
  -moz-box-shadow: 0px 0px 9px -1px #888888;
  -webkit-box-shadow: 0px 0px 9px -1px #888888;
  box-shadow: 0px 0px 9px -1px #888888;
 



}

.call-to {
  padding-top: 3em;
}

.quest-one {
  /* color: black; */
  font-size: 1rem;

}

.main-holder-two {
  justify-content: center;
}

.card {
  /* border: 1px solid black; */
  height: 15em;
  width: 25%;
  margin-left: .5em;
  margin-right: .5em;
  text-align: center;
  /* font-size: 1.5rem; */
  position: relative;
  border-radius: .5em;
  background: #Ffffff;
  -moz-box-shadow: 0px 0px 5px -1px #888888;
  -webkit-box-shadow: 0px 0px 5px -1px #888888;
  box-shadow: 0px 0px 5px -1px #888888;
}

.card h2  {
font-weight: 600;
font-size: 1.5rem;
}

.more-info-button {
  position: absolute;
  bottom: 1em;
  left: 25%;


  width: 50%;
  height: 3em;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  border-radius: .2em;
  border: none;
  background-color: #49974E;
  color: #f3f3f3;
  transition: 2ms;
  opacity: .7;
}

.more-info-button:hover {
  opacity: 1;

}

.request-holder {
  height: 16em;
  /* background: #; */
  background: url('https://lh3.googleusercontent.com/pw/AM-JKLVwCbIFrjDmeUD1TD-PCaftlzm-iiUHUETmSP9Hy-qChtti3Om0h3q6oA9FF98oy1SvuydCPNTFTyO7dl0PL8jfFG8-qKxBxOA8Yx6D-Dxij-3VHlHAkrXxQkcT6aYf0xV-MzRyDnVQePG0Wco8eGPi=w1440-h510-no?authuser=0') no-repeat center;
  border-radius: .5em;
  text-align: center;
  width: 90%;
  max-width: 90em;
  margin: auto;
  margin-top: 1em;
  position: relative;
  color: white;
  -moz-box-shadow: 5px 10px 5px -6px #888888;
  -webkit-box-shadow: 5px 10px 5px -6px #888888;
  box-shadow: 5px 10px 5px -6px #888888;

}


.request-btn {

  height: 3em;
  margin-top: 2em;
  width: 15em;
  color: #fefefe;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  border-radius: .2em;
  border: none;
  background-color: #49974E;
  color: #f3f3f3;
  transition: 2ms;
  opacity: .7;

}

.request-btn:hover {
  opacity: 1;
  transition: 2ms;

}

@media screen and (max-width: 1163px) {
.request-btn {
  margin-top: 1em;
}
}
@media screen and (max-width: 1024px) {
  .request-holder {
    width: 100%;
    height: 15em;

  }

  .main-holder {
    display: block;
  }

  .quest {
    width: 80%;
    margin: auto;
    padding: 1em;

  }

  .card {
    width: 34%;
    margin-left: 0em;
    margin-right: 0em;
    height: 18em;
    padding: 1em;
  }
}


@media screen and (max-width: 600px) {
  .main-holder-two {
    display: block;
  }

  .card {
    width: 93%;
    margin: auto;
    margin-bottom: 1em;
  }

  .quest {
    width: 90%;
    padding-top: 1em;
  }
}