


.footer,
.push {
  height: 65px;
}


.footer {
  text-align: center;
  color: #fefefe;
  border-top: #78F7A5 5px solid;
  background-color: #234041;
 flex-shrink: 0;
 width: 100%;
 /* position: absolute;
 bottom: 0; */
}