.burger-wrap {
  visibility: hidden;
}

.menu-btn {

  border: none;
  position: absolute;
  top: 0em;
  background: transparent;
  height: 3em;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  border: none;
  position: absolute;
  top: -.7em;
  background: transparent;
  right: 0em;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  color: #fefefe;
  z-index: 10;
}
.bm-burger-bars {
  visibility: hidden !important;
  background-color: #fefefe !important;
  width: 2.5em !important;
  height: 1.5px !important;

}
.bm-overlay {
  background: #234041  !important;
  opacity: .9 !important;
}

.moblie-menu-nav-button {
  border: none;
  background: none;
  color: #ffffff ;
  border-bottom: 1px solid transparent;
  margin: 1em;
  
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
  
}

.moblie-menu-nav-button:focus {
  border-bottom: #356E39 1px solid;
}

.moblie-menu-nav-button:hover {
  border-bottom: #ffffff 1px solid;
}

/* 
.nav-button-bar-moblie {
      visibility: visible;
      position: absolute;
      left: 1em;
      border: 2px pink solid;
      width: 100%;
      height: 1em;
} */
.bm-cross-button {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 24px !important;
  top: 15px !important;
  /* background: olive; */
  background: none;
  border: none;
  color: white;


}