.request-email-holder {
  /* display: block; */
  /* display: block; */
  text-align: center;
  
}
.request-form {
  display: inline-block;
  width: 50%;
  border-radius: .5em;
  -moz-box-shadow: 0px 0px 9px -1px #888888;
  -webkit-box-shadow: 0px 0px 9px -1px #888888;
  box-shadow: 0px 0px 9px -1px #888888;
  height: 40em;
  justify-content: center;
  padding-top: 1em;
  background-color: #ffff;
  
}

.request-email-sent {
  display: inline-block;
  margin-top: 1em;
  width: 50%;
  border: 1px solid black;
  height: 30em;
  justify-content: center;
  padding-top: 1em;

}

.request-email-input {
     border: 1px solid black;
     width: 90%;
     height: 5%;
     padding-left: 1em;
     margin-top: 1em;
     margin-bottom: 1em;
           font-family: 'Montserrat';
           font-style: normal;
           font-weight: 400;
background: #EBEBEB;
border-radius: .19em;

}
 
.request-email-body-input {
      border: none;
      overflow: auto;
      outline: none;
  background: #EBEBEB;
  border-radius: .19em;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;

      resize: none;
      border: 1px solid black;
      width: 90%;
      height: 50%;
      max-height: 17em;
     padding-left: 1em;
     margin-top: 1em;
     margin-bottom: 1em;
     padding-top: .5em; 
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;



}

.request-email-button {
  float: right;
  margin-right: 2.8em;
 
      margin-top: .5em;
      width: 9em;
      height: 3em;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      border-radius: .2em;
      border: none;
      background-color: #49974E;
      color: #f3f3f3;
      transition: 2ms;
}
.loader-holder-request {
      float: right;
      margin-top: -5.5em;
      margin-right: 1em;
      
}

.loader-request {
  margin-top: 5em;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #1A1F16;
  border-right: 16px solid #46543B;
  border-bottom: 16px solid #356E39;
  border-left: 16px solid #49974E;
  width: 25px;
  height: 25px;
  animation: spin 2s linear infinite;
}

@media screen and (max-width: 1024px) {
.request-form {
  width: 100%;
  height: 50em;
  margin-bottom: 6em;
}
}