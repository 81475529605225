.App-header {
  background-color: #234041;
  min-height: 1em;
  height: 13em;
  width: 100%;
  display: flex;
  color: white;
  position: relative;
  border-bottom: #78F7A5 5px solid;
  z-index: 1;
}

.company-logo-name {
  position: absolute;
bottom: 1.3em;
left: 2.3em;
font-size: 1rem;
}
.company-logo-sub {
 position: absolute;
   font-family: 'Birthstone';
   font-style: normal;
   font-weight: 400;
bottom: 0em;
 left: 2.9em;
font-size: 1.18rem;

}
.serving {
  position: absolute;
  left: -20em;
  top: 5.5em;
  font-size: 0.8rem;
}

.company-name {
  float: left;
  padding: 1em;
}

.company-number {
  position: absolute;
  right: 5.2em;
  bottom: 9.5em;
  opacity: .7;
}

.company-number:hover {
  opacity: 1;
}

.address-img-div {
  /* background-color: #fefefe;
  border-radius: 1em;
  width: 2em;
  height: 2em; */
  position: absolute;
  right: 1.5em;
  top: 12px;


  display: block;
  width: 40px;
  height: 40px;
  background: #E6E7ED;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  text-align: center;


}

.holler {
  opacity: .7;
  transition: 10ms;
}

.holler:hover {
  opacity: 1;
  transition: 10ms;
}

.img-div {
  /* background-color: #fefefe;
  border-radius: 1em;
  width: 2em;
  height: 2em; */
  position: absolute;
  right: 6.9em;
  bottom: 0em;


  display: block;
  width: 40px;
  height: 40px;
  background: #E6E7ED;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  text-align: center;

}

.company-number-img {
  margin-top: 11px;
  /* opacity: .2
  ; */
}

.cart {
  position: relative;
  margin-top: 11px;
  top: -3.2em;
  left: 1.5em;
  width: 1.3em;
  background: red;
  border-radius: 1em;
  text-align: center;
  border: 1px solid red;
  font-size: 1rem;
}

.company-address {
  position: absolute;
  right: 0em;
  /* bottom: .2em; */
}

.number {
  font-size: .8rem;
}




@media screen and (max-width: 1024px) {
  .img-div {
    position: absolute;
    right: -.5em;
    /* bottom: -3.3em; */
  }

  .number {
    visibility: hidden;
  }
}

@media screen and (max-width: 468px) {
  .serving {
    width: 15em;
    left: -15em;
  }
}

@media screen and (max-width: 400px) {
  .serving {
    width: 100%;
    left: -6em;
  }
}

@media screen and (max-width: 300px) {
  .company-number {
    visibility: hidden;
  }

}